import React, { useState, useEffect } from 'react'
import { TextField, Badge } from '@material-ui/core'
import PageBase from '@ui/PageBase'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Websocket from 'react-websocket'
import axios from 'axios'

// LOCAL
import { MessageList } from '@ui/clipboard'

const ClipBoard = () => {
  const [text, $text] = useState('')
  const [conn, $conn] = useState(false)
  const [users, $users] = useState(0)

  const baseTitle = 'Ad0.dev'

  const handleEnter = ({ keyCode, target }) =>
    keyCode === 13 && handleSend(text)

  const handleSend = text => {
    const output = new FormData()
    output.append('clip', text)
    const opts = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
    const endpoint = 'https://red.ad0.dev/clip'
    axios
      .post(endpoint, output, opts)
      .then(() => $text(''))
      .catch(console.log)
  }
  const handleUsers = msg => {
    if (users !== msg * 1) $users(msg * 1)
  }

  const handleContext = ev => {
    ev.preventDefault()
    navigator.clipboard
      .readText()
      .then(handleSend)
      .catch(console.log)
  }

  useEffect(() => {
    document.title = !!users ? `${baseTitle} - ${users}` : baseTitle
    return () => (document.title = baseTitle)
  }, [users])

  const userBadge = (
    <Badge
      badgeContent={users}
      invisible={!conn}
      color={users > 1 ? 'primary' : 'secondary'}>
      <AccountCircleIcon />
    </Badge>
  )

  return (
    <PageBase title='vaperclip' topRight={userBadge}>
      <Websocket
        url='wss://red.ad0.dev/ws/users'
        onMessage={handleUsers}
        onOpen={() => $conn(true)}
        onClose={() => $conn(false)}
      />
      <TextField
        fullWidth
        value={text}
        onChange={({ target }) => $text(target.value)}
        onKeyUp={handleEnter}
        InputLabelProps={{ shrink: true }}
        inputProps={{ onContextMenu: handleContext }}
      />
      <MessageList />
    </PageBase>
  )
}

export default ClipBoard
