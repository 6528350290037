import React from 'react'
import { Icon, Button, ListItem, ListItemIcon } from '@material-ui/core'
import axios from 'axios'
// LOCAL
import { useStyles } from '@style'
import { useRouter } from '@hooks'

const local = ({
  spacing,
  palette: {
    common: { white },
  },
}) => ({
  hide: { display: 'none !important' },
  white: { color: white },
  overflow: { overflow: 'visible' },
  formField: {
    margin: spacing(0, 1),
    marginBottom: spacing(2),
    width: 'calc(100% - 16px)',
  },
})

const FileUpload =({
  onChange,
  value = [],
  label = 'Upload File',
  single = true,
  icon = 'cloud-upload-alt',
}) => {
  const c = useStyles(local)
  const {push, pathname} = useRouter()
  const handleChange = ({ target }) => {
    if (!!target.files.length) {
      axios({
        method: 'post',
        url: 'https://red.ad0.dev/daily',
        data: target.files[0],
        headers: { 'Content-Type': 'application/json'}
      })
      .then(() => onChange())
      .catch(console.error)
      .finally(() => {
        if (pathname !== '/sudoku/today') {
          push('/sudoku/today')
        } else {
          window.location.reload()
        }
      })
    }
  }

  return (
    <div className={c.formField}>
      <input
        value=''
        type='file'
        className={c.hide}
        onChange={handleChange}
        multiple={false}
        id='contained-button-file'
        accept='.json'
      />
      <label htmlFor='contained-button-file'>
        <Button
          variant={!!value.length ? 'contained' : 'outlined'}
          fullWidth
          color='primary'
          component='span'
        >
          <ListItemIcon className={!!value.length ? c.white : null}>
            <Icon className={`fas fa-${icon}`} classes={{ root: c.overflow }} />
          </ListItemIcon>
          <div style={{ paddingTop: 2 }}>{label}</div>
        </Button>
      </label>
      {!!value.length &&
        value.map((file, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <Icon className='fas fa-paperclip' />
            </ListItemIcon>
            {file.name}
          </ListItem>
        ))}
    </div>
  )
}

export default FileUpload
