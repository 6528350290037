import React from 'react'
import { Snackbar } from '@material-ui/core'


const Snack = ({ text, ...snackBarProps }) => {
  return (
    <div>
      <Snackbar
        // PROVIDE open & onClose & action
        {...snackBarProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{text}</span>}
      />
    </div>
  )
}

export { Snack }