import React, { useState, useEffect } from 'react'
import { Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useCalculatedValue } from '@hooks'
//LOCAL
import Row from './Row'

const pageStyle = ({
  spacing,
  palette: {
    primary: { main },
    secondary: { light }
  }
}) => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(5px)',
    borderRadius: 5,
    margin: spacing(1),
    flexWrap: 'wrap',
    height: 'fit-content'
  },
  title: {
    textAlign: 'center',
    textShadow: `-2.5px 0px 0px ${main}, 2.5px 0px 0px ${light}`,
    fontFamily: 'vapor',
    letterSpacing: 5,
    fontSize: '4.5vmin',
    margin: 'auto'
  },
  table: {
    textAlign: 'initial'
  },
  loading: {
    margin: spacing(2)
  }
})

const Availables = ({ row, col, reg, all }) => {
  const c = makeStyles(pageStyle)()
  const { selected_cells } = useCalculatedValue()
  const [loading, $loading] = useState(true)
  const shouldDisplay = !!selected_cells.length && !loading

  useEffect(() => {
    const someAvailables = [all, reg, row, col].some(group => !!group.length)
    $loading(!someAvailables)
    return () => {
      $loading(true)
    }
  }, [row, col, reg, all])
  return (
    shouldDisplay && (
      <div className={c.container}>
        <Typography variant='h5' className={c.title}>
          AVAILABLE
        </Typography>
        <div className={c.table}>
          {loading ? (
            <CircularProgress className={c.loading} />
          ) : (
            <>
              {!!all.length && <Row title='CELL:' digits={all} />}
              {!!reg.length && <Row title='REGION:' digits={reg} />}
              {!!row.length && <Row title='ROW:' digits={row} />}
              {!!col.length && <Row title='COLUMN:' digits={col} />}
            </>
          )}
        </div>
      </div>
    )
  )
}

export default Availables
