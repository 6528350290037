import React from 'react'
import { PageBase } from '@ui'
import { useStyles } from '@style'

const local = ({spacing, }) => ({
  frame: {
    flexGrow: 1,
    // position: 'relative',
    // top: 0,
    // left: 0,
    // right: 0,
    height: '100%',
    width: '100%',
    border: 'none',
    // marginTop: spacing(4),
  }
})

const UIFrame = () => {
  const c = useStyles(local)
  return (
    <PageBase title={'Node-Red Dashboard'}>
        <iframe
          title='node-red-ui'
          src='https://red.ad0.dev/ui'
          className={c.frame}
        />
    </PageBase>
  )
}

export default UIFrame
