import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { useStyles } from '@style'
import Menu from '@ui/menu'
const blankFn = () => {}

const PageBase = ({
  title = 'page title',
  children,
  keydown = blankFn,
  rclick = blankFn,
  tree = false,
  topRight = null
}) => {
  const pageStyle = ({
    spacing,
    palette: {
      secondary: { light },
      tertiary,
      purple
    }
  }) => ({
    wrapper: {
      height: '100vh',
      width: '100vw',
      position: 'relative',
      outline: 'none'
    },
    paper: {
      margin: spacing(2),
      padding: spacing(2),
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundBlendMode: 'overlay',
      backgroundImage: `linear-gradient(to top,${tertiary},${light},${purple})${
        tree ? ', url(/trees.gif)' : ''
      }`,
      backgroundAttachment: 'fixed',
      backgroundRepeat: ' no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      filter: 'contrast(0.7)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    flex_stuff: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexGrow: 0
    },
    contentWrapper: {
      flexGrow: 1
    }
  })
  const c = useStyles(pageStyle)

  return (
    <div
      className={c.wrapper}
      onKeyDown={keydown}
      onContextMenu={rclick}
      tabIndex='0'>
      <Paper className={c.paper}>
        <div className={c.flex_stuff}>
          <Menu>
            <Typography variant='h6' className='vaporwave double no-select'>
              {title}
            </Typography>
          </Menu>
          {!!topRight && topRight}
        </div>
        <div className={c.contentWrapper}>
          {children}
        </div>
      </Paper>
    </div>
  )
}
export default PageBase
