import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'

//LOCAL
import NumberButton from './NumberButton'
import { numberSelect, clearNumberSelect } from '@core/actions/sudoku'

const pageStyle = ({ spacing }) => ({
  buttonGroup: {
    flexWrap: 'wrap',
    width: '35vmin',
    display: 'flex',
  },
  container: {
    // padding: spacing(0.5),
    margin: spacing(1),
    backgroundBlendMode: 'overlay',
    // backgroundImage: `linear-gradient(to left, cyan, magenta)`,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(5px)',
    borderRadius: '5px',
    overflow: 'hidden'
  }
})

const NumberMenu = ({ remaining }) => {
  const dp = useDispatch()
  const { buttonGroup, container } = makeStyles(pageStyle)()
  const { number_select, autoSolve } = useSelector(({ sudoku }) => sudoku.controls)

  const handleClick = (num) => {
    dp(numberSelect({ payload: num, autoSolve }))
  }

  const handleX = ev => {
    dp(clearNumberSelect())
  }
  return (
    <div className={container}>
      <div className={buttonGroup}>
        {remaining.map((val, ind) => {
          const num = ind + 1
          return (
            <NumberButton
              remaining={val}
              number={num}
              key={ind}
              selected={number_select}
              onClick={() => handleClick(num)}
            />
          )
        })}
        <NumberButton number='X' onClick={handleX} />
      </div>
    </div>
  )
}

export default NumberMenu
