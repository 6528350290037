export const highlightCell = ({ ind, ctrl = false, drag = false }) => ({
  type: 'HIGHLIGHT_CELL',
  ind,
  ctrl,
  drag
})
export const numberSelect = ({payload, autoSolve}) => ({ type: 'NUMBER_SELECT', payload, autoSolve })
export const clearNumberSelect = () => ({ type: 'NUMBER_SELECT', active: false })
export const clearSelections = () => ({ type: 'CLEAR_SELECTIONS' })
export const changePuzzle = payload => ({ type: 'CHANGE_PUZZLE', payload })
export const createPuzzle = () => ({ type: 'CREATE_PUZZLE' })
export const lockPuzzle = payload => ({type: 'LOCK_CELLS', payload })
export const toggleControl = ctrl => ({ type: 'TOGGLE_CONTROL', ctrl })
export const updateBig = payload => ({ type: 'UPDATE_BIG', ...payload })