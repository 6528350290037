import Clipboard from './Clipboard'
import Sudoku from './Sudoku'
// import Theremin from './Theremin'
import UIFrame from './UIFrame'

const routes = [
  {
    path: '/',
    component: Clipboard,
    text: 'vaperclip',
    icon: 'paperclip',
  },
  {
    path: '/sudoku',
    component: Sudoku,
    text: 'sudoku',
    icon: 'th',
  },
  {
    path: '/sudoku/:id',
    component: Sudoku,
    text: 'sudoku',
    icon: false,
  },
  // {
  //   path: '/sudok2',
  //   component: Sudok2,
  //   text: 'sudok2'
  // },
  //  {
  //    path: '/theremin',
  //    component: Theremin,
  //    text: 'theremin',
  //    icon: 'wave'
  //  },
  {
    path: '/ui',
    component: UIFrame,
    text: 'dashboard',
    icon: 'tachometer-alt',
  },
]

export { Clipboard, Sudoku, UIFrame }
export default routes
