import React from 'react'
import { Typography } from '@material-ui/core'
//LOCAL
import { useStyles } from '@style'
import { purple, numArr } from '@util/constants'

const pageStyle = ({ spacing }) => ({
  row: {
    display: 'flex',
    margin: spacing(1)
  },
  title: {
    width: '40%',
    fontSize: '3vmin',
    fontWeight: 'bold',
    lineHeight: 1,
    fontFamily: 'vapor'
  },
  chip: {
    fontSize: '3vmin',
    lineHeight: 1,
    fontFamily: 'vapor',
    marginRight: 4
  }
})

const Row = ({ title, digits }) => {
  const c = useStyles(pageStyle)
  const chips = numArr.map(chip => [
    chip,
    digits.includes(chip) ? purple : 'rgba(255, 255, 255, 0.5)',
  ])
  // console.log(chips)
  return (
    <div className={c.row}>
      <Typography variant='h6' className={c.title} align='right' gutterBottom>
        {title}
      </Typography>
      <>
        {chips.map(([chip, color], i) => (
          <Typography key={i} variant='h6' className={c.chip} style={{color: color}} gutterBottom>
            {chip}
          </Typography>
        ))}
      </>
    </div>
  )
}

export default Row
