import { blank } from '@util/constants'

/** puzzle_reducer
 * @param {Array[]} [puzzle=blank] state
 * @param {Object} action type and payload
 * @param {string} action.type
 * @param {Array[]} action.payload
 * @returns {Array[]} puzzle
 */
const puzzle_reducer = (puzzle = blank, action) => {
  switch (action.type) {
    case 'CHANGE_PUZZLE':
      return action.payload
    case 'CREATE_PUZZLE':
      return blank
    default:
      return puzzle
  }
}

export default puzzle_reducer
