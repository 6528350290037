import React from 'react'
//LOCAL
import RClick from '@ui/components/RClick'
import routes from '@pages'
import { capitalize } from '@util/functions'
import { useRouter } from '@hooks'

const TitleWrapper = ({ children }) => {
  const { push } = useRouter()
  const redirect = path => () => push(path)

  const routesOnly = routes
    .filter(({ icon }) => !!icon)
    .map(({ path, text, icon }) => ({
      text: capitalize(text),
      onClick: redirect(path),
      icon
    }))
  const nodeRed = {
    text: 'RED',
    onClick: () => window.location = 'https://red.ad0.dev/',
    icon: 'project-diagram',
    color: 'secondary'
  }
  const actions = [...routesOnly, nodeRed]

  return <RClick actions={actions}>{children}</RClick>
}

export default TitleWrapper
