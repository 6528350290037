import { types } from '@util/constants'

const checkConflicts = (board, cell, type) =>
  !!!board.filter(
    item => item[type] === cell[type] && item.value === cell.value
  ).length
  
const noConflicts = (board, cell) =>
  types.every(type => checkConflicts(board, cell, type))

const valid_lil_numbers = ({ board }) => ind => type =>
  board[ind][type].filter(num =>
    noConflicts(board, { ...board[ind], value: num })
  )

export default valid_lil_numbers