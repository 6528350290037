import React from 'react'
//LOCAL
import { useStyles } from '@style'
import { useCalculatedValue } from '@hooks'
import Cell from './Cell'

const pageStyle = ({ spacing }) => ({
  root: {
    display: 'flex',
    width: `calc(33% - ${spacing(1)}px)`,
    height: `calc(33% - ${spacing(1)}px)`,
    flexWrap: 'wrap'
  }
})

const Region = ({ region, drag }) => {
  const { root } = useStyles(pageStyle)
  const { region_cells } = useCalculatedValue()
  const toCell = ({ ind }) => <Cell ind={ind} key={ind} drag={drag} />

  return <div className={root}>{region_cells(region).map(toCell)}</div>
}

export default Region
