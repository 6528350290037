import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
// LOCAL
import { Snack, IconAction } from '@ui/components'
const defaultConfig = {
  actions: [],
  props: {}
}

const useSnack = (text, config=defaultConfig) => {
  const [open, $open] = useState(false)

  const onClose = () => {
    !!config.onClose && config.onClose()
    $open(false)
  }
  const closeAction = {
    label: 'Close',
    onClick: onClose,
    Icon: CloseIcon
  }

  const actionList = [closeAction, ...config.actions].map((props, i) => (
    <IconAction key={i} {...props} />
  ))

  const action = actionList
  const trigger = () => $open(true)
  const snackBarProps = { open, onClose, text, action, ...config.props }
  const component = (<Snack {...snackBarProps} />)

  return [trigger, component]
}

export { useSnack }
