import React from 'react'
// import { useSelector } from 'react-redux'
import { Button, Box } from '@material-ui/core'
//LOCAL
import { useStyles } from '@style'
import { gradientColors } from '@util/constants'

const NumberButton = ({ remaining, number, selected, ...props }) => {
  // const filter = useSelector(({ preferences }) => preferences.css)
  // const filter = 'invert() hue-rotate(270deg)'

  const percent = (100 * remaining) / 9
  const pageStyle = ({
    palette: {
      notBlack,
      primary: { main },
      secondary: { light }
    }
  }) => ({
    button: {
      fontFamily: 'vapor',
      fontSize: '7vmin',
      lineHeight: 0.85,
      textShadow: selected === number
          ? `0px 0px 6px ${light}`
          : `0px 0px 6px ${main}`,
      color: 'white',
      // color: selected === number ? notBlack : 'white',
      // margin: spacing(.5, 0),
      background: !!remaining
        ? `linear-gradient(transparent ${percent}%, ${gradientColors[remaining]} ${percent}%), linear-gradient(to top, white, rgba(255, 255, 255, 0.2))`
        : 'linear-gradient(to top, white, rgba(255, 255, 255, 0.2))',
        // : 'none',
      backgroundBlendMode: 'hard-light',
      // backgroundImage: `linear-gradient(to top, white, rgba(255, 255, 255, 0.2))`,
      border: 'none', //'1px solid white',
      minWidth: 0,
      width: '100%',
      '&:hover': {
        filter: 'brightness(120%)'
      },
      borderRadius: 0
      // height: '8vmin'
      // filter: selected === number ? 'contrast(250%)' : 'none',
    }
  })

  const { button } = useStyles(pageStyle)

  return (
    <Box width={1 / 5} display='flex' justifyContent='center'>
      <Button className={button} {...props}>
        {number}
      </Button>
    </Box>
  )
}

export default NumberButton
