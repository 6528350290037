import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'

//LOCAL
import NumberMenu from './NumberMenu'
import Dial from './Dial'
import Availables from './Availables'
import Timer from './Timer'
import { FileUpload } from '@ui/components'
import { useCalculatedValue } from '@hooks'

const ControlPanel = () => {
  const dp = useDispatch()
  const [showUpload, $showUpload] = useState(false)
  const {
    autosolve_highlander: { shouldAutoSolve, updateIndex, updateValue },
    available_values,
    remaining_values,
  } = useCalculatedValue()

  useEffect(() => {
    if (shouldAutoSolve) {
      const data = {
        type: 'UPDATE_BIG',
        ind: updateIndex,
        value: updateValue,
      }
      dp(data)
    }
  }, [shouldAutoSolve])

  return (
    <Box display='flex' margin='0 4px'>
      <div>
        <NumberMenu remaining={remaining_values} />
        <Timer />
        {showUpload && <FileUpload onChange={() => $showUpload(false)} />}
        <Availables {...available_values} />
      </div>
      <Dial dailyUpload={() => $showUpload(true)} />
    </Box>
  )
}

export default ControlPanel
