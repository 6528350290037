import { useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
// LOCAL
import { useRouter } from '@hooks'
import { changePuzzle } from '@core/actions/sudoku'
import { blank } from '@util/constants'

const usePuzzle = () => {
  const { params, search = '' } = useRouter()
  const id = params.hasOwnProperty('id') ? params.id : false
  const dp = useDispatch()
  const base = 'https://red.ad0.dev/puzzle'
  const url = !!id ? `${base}/${id}` : `${base}${search}`

  useEffect(() => {
    axios
      .get(url)
      .then(({ data }) => {
        dp(changePuzzle(data))
      })
      .catch(console.log)
  }, [])
}

export { usePuzzle, blank }
