import { mapPuzzle } from '@util/functions'
import { purple, primary, blank } from '@util/constants'

/** board_reducer
 * @param {Array} [board] state
 * @param {Object} action type and payload
 * @param {string} action.type
 * @param {number} [action.ind]
 * @param {Boolean} [action.ctrl]
 * @param {Boolean} [action.drag]
 * @param {number} [action.value] - for corner/middle numbers
 * @param {string} [action.key] - corner or middle
 * @param {string} [action.payload] - puzzle array to be mapped
 * @returns {Array} board
 */
const board_reducer = (board = mapPuzzle(blank), action) => {
  // BOARD HELPERS
  const clearSelections = () => {
    if (!board.some(({ selected, color }) => !!selected || !!color))
      return false
    board = board.map(cell => ({ ...cell, selected: false, color: false }))
  }
  // SWITCH START
  switch (action.type) {
    case 'CHANGE_PUZZLE':
      return mapPuzzle(action.payload)
    case 'HIGHLIGHT_CELL': {
      const { ind, ctrl, drag } = action
      const current = board[ind]
      if (current.locked) return board
      // click without control
      if (!ctrl) {
        // you're dragging over it select it
        // otherwise just select it
        if (!drag) clearSelections()
        board[ind].selected = true
      } else {
        // click with control
        board[ind].selected = !drag ? !current.selected : !current.locked
      }
      return [...board]
    }
    case 'UPDATE_BIG': {
      if (action.ind !== undefined) {
        return board.map((cell, i) => i === action.ind ? {...cell, value: action.value } : cell)
      } else {
        return board.map(cell => ({
          ...cell,
          value: cell.locked
            ? cell.value
            : cell.selected
            ? action.value
            : cell.value
        }))
      }
    }
    case 'UPDATE_SMALL': {
      const { value, key } = action
      if (!!value) {
        return board.map(cell => ({
          ...cell,
          [key]: cell.selected
            ? cell[key].includes(value)
              ? cell[key].filter(no => no !== value)
              : [...cell[key], value].sort((a, b) => a - b)
            : cell[key]
        }))
      } else {
        return board.map(cell => ({
          ...cell,
          [key]: cell.selected ? [] : cell[key]
        }))
      }
    }
    case 'NUMBER_SELECT': {
      const { payload, autoSolve } = action
      const hasValue = board.filter(({ value }) => payload === value)
      if (!!payload) {
        const mapped = board.map(cell => ({
          ...cell,
          color: cell.value === payload ? purple : false,
          selected:
            !cell.value &&
            !hasValue.some(
              ({ row, col, reg }) =>
                row === cell.row || col === cell.col || reg === cell.reg
            )
        }))
        if (!autoSolve) return mapped
        const included = mapped
          .filter(({ selected }) => selected)
          .filter(
            ({ row, col, reg }, i, arr) =>
              !arr.some((cell, j) => j !== i && cell.reg === reg)
          )
          .map(({ ind }) => ind)
        if (included.length) {
          return mapped.map(cell => ({
            ...cell,
            value: included.includes(cell.ind) ? payload : cell.value,
            color: included.includes(cell.ind) ? primary : cell.color
          }))
        }
        return mapped
      } else {
        clearSelections()
        return board
      }
    }
    case 'CLEAR_SELECTIONS': {
      return board.map(cell => ({
        ...cell,
        color: false,
        selected: false
      }))
    }
    case 'CREATE_PUZZLE': {
      return mapPuzzle(blank)
    }
    default:
      return board
  }
}

export default board_reducer
