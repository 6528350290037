import React from 'react'
import { Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
//LOCAL
import { useStyles } from '@style'
import { useCalculatedValue } from '@hooks'
import BigNumber from './BigNumber'
import LilNumber from './LilNumber'

const Cell = ({ ind, drag }) => {
  const dp = useDispatch()
  // const filter = useSelector(({ preferences }) => preferences.css)
  const { valid_lil_numbers, cell } = useCalculatedValue()
  const lilNumbers = valid_lil_numbers(ind)
  const { value, locked, selected, color } = cell(ind)

  const pageStyle = ({ palette: { notBlack } }) => ({
    cell_style: {
      backgroundBlendMode: 'overlay',
      backgroundColor: color ? color : notBlack,
      // backgroundImage: `linear-gradient(to top, ${notBlack}, rgba(255, 255, 255, 0.5))`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'vapor',
      userSelect: 'none',
      border: `1px solid white`,
      borderRadius: 3,
      // filter: selected ? filter : 'none'
      filter: selected ? 'contrast(200%) brightness(130%)' : 'none',
    },
  })
  const { cell_style } = useStyles(pageStyle)
  const mouseDown = ev => dp({ type: 'HIGHLIGHT_CELL', ind, ctrl: ev.ctrlKey })

  const mouseIn = ev => {
    if (drag) dp({ type: 'HIGHLIGHT_CELL', ind, ctrl: true })
  }
  return (
    <Box
      width={1 / 3}
      height={1 / 3}
      className={cell_style}
      onMouseEnter={mouseIn}
      onMouseDown={mouseDown}
    >
      {!!value ? (
        <BigNumber {...{ value, locked }} />
      ) : (
        <LilNumber
          corner={lilNumbers('corner')}
          middle={lilNumbers('middle')}
        />
      )}
    </Box>
  )
}

export default Cell
