import React, { useState } from 'react'
import { useStyles } from '@style'
//LOCAL
import Region from './Region'
import { numArr } from '@util/constants'

const pageStyle = ({
  spacing,
  palette: {
    primary: { main }
  }
}) => ({
  grid_container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: spacing(1),
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 'calc(25vmin * 3)',
    height: 'calc(25vmin * 3)',
    backgroundBlendMode: 'overlay',
    // backgroundImage: `linear-gradient(to right, cyan, magenta)`,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(5px)',
    borderRadius: 5
  }
})

const Board = () => {
  const [drag, $drag] = useState(false)
  const { grid_container } = useStyles(pageStyle)
  const mouseDown = () => $drag(true)
  const mouseUp = () => $drag(false)
  const toRegion = (value, i) => <Region key={value} region={i} drag={drag} />
  return (
    <div className={grid_container} onMouseDown={mouseDown} onMouseUp={mouseUp}>
      {numArr.map(toRegion)}
    </div>
  )
}

export default Board
