import { useMemo } from 'react'
import { useSelector } from 'react-redux'
// LOCAL
import autosolve_highlander from './autosolve_highlander'
import available_values from './available_values'
import remaining_values from './remaining_values'
import valid_lil_numbers from './valid_lil_numbers'

const useCalculatedValue = () => {
  const {
    sudoku: { board },
  } = useSelector(store => store)

  const helpers = {
    cell: ind => board[ind],
    filled: board.every(({value}) => !!value),
    region_cells: region => board.filter(({ reg }) => reg === region),
    selected_cells: board.filter(({ selected }) => selected),
    values: board.map(({ value }) => value),
  }

  const arg = {board, ...helpers}

  return useMemo(
    () => ({
      ...helpers,
      autosolve_highlander: autosolve_highlander(arg),
      available_values: available_values(arg),
      remaining_values: remaining_values(arg),
      valid_lil_numbers: valid_lil_numbers(arg),
    }),
    [board]
  )
}

export { useCalculatedValue }
