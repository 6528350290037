import React from 'react'
//LOCAL
import { useStyles } from '@style'

const num = {
  flexGrow: 1,
  margin: 4,
  lineHeight: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '1.7vmin',
  fontWeight: 'bold',
}

const pageStyle = ({
  palette: {
    primary: { main },
    secondary: { light },
  },
}) => ({
  lil_num: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  corner_num: {
    ...num,
    color: main,
    alignSelf: 'flex-start',
  },
  middle_num: {
    ...num,
    color: light,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
})

const LilNumber = ({ corner, middle }) => {
  const { lil_num, corner_num, middle_num } = useStyles(
    pageStyle
  )
  return (
    <div className={lil_num}>
      <div className={corner_num}>{corner}</div>
      <div className={middle_num}>{middle}</div>
    </div>
  )
}

export default LilNumber
