import { createMuiTheme } from '@material-ui/core/styles'
import { primary, secondary, notBlack, purple, tertiary } from '@util/constants'
const themeName = 'Ad0'
const palette = {
  primary: { main: primary, contrastText: '#ffffff' },
  secondary: { main: secondary },
  tertiary,
  notBlack,
  purple,
  type: 'dark'
}
const overrides = {
  MuiTouchRipple: {
    root: {
      color: palette.primary.main
    }
  }
}
const themeObj = {
  themeName,
  palette,
  overrides
}

export default createMuiTheme(themeObj)
