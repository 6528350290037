import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
//LOCAL
import { PageBase } from '@ui'
import { useStyles } from '@style'
import { usePuzzle } from '@hooks'
import { Board, ControlPanel } from '@ui/sudoku'

const pageStyle = () => ({
  flexBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
})

const Sudoku = () => {
  const dp = useDispatch()
  const { tree } = useSelector(({ sudoku }) => sudoku.controls)
  const { flexBox } = useStyles(pageStyle)
  
  usePuzzle()

  const keydown = ev => {
    const codes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 46, 8]
    const { keyCode, shiftKey, ctrlKey } = ev
    // console.log('keyCode is ', keyCode)
    if (keyCode !== 123) {
      ev.stopPropagation()
      ev.preventDefault()
      // !id && !search && checkPass(ev.key)
      if (codes.includes(keyCode)) {
        let value = codes.indexOf(keyCode)
        if (value >= 10) value = 0
        if (!shiftKey && !ctrlKey) {
          dp({ type: 'UPDATE_BIG', value })
        } else if (ctrlKey && !shiftKey) {
          dp({ type: 'UPDATE_SMALL', value, key: 'corner' })
        } else if (shiftKey && !ctrlKey) {
          dp({ type: 'UPDATE_SMALL', value, key: 'middle' })
        } else {
        }
      }
    }
  }

  return (
    <PageBase keydown={keydown} title={'sudoku'} tree={tree}>
      <div className={flexBox}>
        <Board />
        <ControlPanel />
      </div>
    </PageBase>
  )
}

export default Sudoku
