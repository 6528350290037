import React, { useEffect } from 'react'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import {
  PlayCircleFilled,
  PauseCircleFilled,
  Refresh
} from '@material-ui/icons'
//LOCAL
import { useStopwatch, useCalculatedValue } from '@util/hooks'

const TimerDisplay = ({ c: {icon, timer} }) => {
  const {
    isRunning,
    elapsedTime,
    startTimer,
    stopTimer,
    resetTimer
  } = useStopwatch()
  const { filled } = useCalculatedValue()

  const handleStartStop = () => {
    isRunning ? stopTimer() : startTimer()
  }

  const hours = Math.floor((elapsedTime % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((elapsedTime % (60 * 60)) / 60)
  const seconds = Math.floor(elapsedTime % 60)
  const timerReadout =
    (hours ? (hours > 9 ? hours : '0' + hours) : '00') +
    ':' +
    (minutes ? (minutes > 9 ? minutes : '0' + minutes) : '00') +
    ':' +
    (seconds > 9 ? seconds : '0' + seconds)

  useEffect(() => {
    if (!isRunning && !filled) startTimer()
    if (isRunning && filled) stopTimer()
  }, [filled])

  return (
    <>
      <Typography variant='h5' className={timer}>
        {timerReadout}
      </Typography>
      <Tooltip title={isRunning ? 'Pause' : 'Resume'} placement='bottom'>
        <IconButton className={icon} size='small' onClick={handleStartStop}>
          {isRunning ? <PauseCircleFilled /> : <PlayCircleFilled />}
        </IconButton>
      </Tooltip>
      <Tooltip title='Reset' placement='bottom'>
        <IconButton className={icon} size='small' onClick={resetTimer}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default TimerDisplay
