import React, { useState } from 'react'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@material-ui/core'
//LOCAL
import { useStyles } from '@style'
import { useSafety, useRouter } from '@util/hooks'
import { boardFromString } from '@util/functions'


import {
  toggleControl,
  changePuzzle,
  createPuzzle,
} from '@core/actions/sudoku'

const icon = str => <Icon className={`fas fa-${str}`} />

const local = ({ spacing, palette: { purple, notBlack } }) => ({
  dial: {
    marginTop: spacing(1)
  },
  purple: {
    backgroundColor: purple,
    '&:hover, &:focus': {
      backgroundColor: purple,
      filter: 'brightness(150%)'
    }
  },
  notBlack: {
    backgroundColor: notBlack,
    '&:hover, &:focus': {
      backgroundColor: notBlack
    }
  }
})

const Dial = ({ history, dailyUpload }) => {
  const c = useStyles(local)
  const [open, $open] = useState(false)
  const {
    puzzle,
    board,
    controls: { autoSolve, create, tree }
  } = useSelector(({ sudoku }) => sudoku)
  const dp = useDispatch()
  const [resetTog, resetBool, resetFn] = useSafety(changePuzzle)
  const [createTog, createBool, createReset] = useSafety(createPuzzle)
  const { push } = useRouter()

  const handleOpen = () => $open(true)
  const handleClose = () => $open(false)
  const ctrlToggle = str => () => dp(toggleControl(str))

  const handleResetPuzzle = () => resetTog(puzzle)
  const handleCreate = () => createTog()
  const handleLock = () => {
    const str = board.reduce((ac, { col, value }) => `${ac + value}${col === 8 ? '-' : ''}`,'')
    const updated = boardFromString(str)
    dp(changePuzzle(updated))
  }

  const handleToday = () => push('/sudoku/today')
  const handleRandom = () => {
    push('/sudoku')
    window.location.reload()
  }

  const onContext = event => {
    event.preventDefault()
    event.stopPropagation()
    dailyUpload()
  }

  const actionList = [
    {
      title: 'toggle autosolve',
      icon: 'crosshairs',
      onClick: ctrlToggle('autoSolve'),
      active: autoSolve
    },
    {
      title: create ? 'lock puzzle' : 'new puzzle',
      icon: create ? 'lock' : 'plus',
      onClick: create ? handleLock : handleCreate,
      active: createBool,
      out: createReset
    },
    { title: "get today's puzzle", icon: 'calendar', onClick: handleToday, onContext },
    { title: 'get random puzzle', icon: 'random', onClick: handleRandom },
    {
      title: 'reset puzzle',
      icon: 'redo-alt',
      onClick: handleResetPuzzle,
      active: resetBool,
      out: resetFn
    },
    {
      title: 'toggle tree',
      icon: 'tree',
      onClick: ctrlToggle('tree'),
      active: tree
    }
  ]

  return (
    <SpeedDial
      classes={{ fab: c.purple }}
      className={c.dial}
      onMouseLeave={handleClose}
      onOpen={handleOpen}
      open={open}
      direction='down'
      // openIcon={<>{icon('angle-left')}</>}
      icon={
        <SpeedDialIcon
          icon={<>{icon('angle-down')}</>}
          // openIcon={<>{icon('angle-left')}</>}
        />
      }
      ariaLabel='SpeedDial'>
      {actionList.map(({ title, icon, onClick, onContext = () => {}, active = false, out }) => (
        <SpeedDialAction
          key={title}
          classes={{ fab: active ? c.purple : c.notBlack }}
          icon={<Icon className={`fas fa-${icon}`} />}
          tooltipTitle={title}
          onClick={onClick}
          onMouseOut={out}
          onContextMenu={onContext}
          tooltipPlacement='right'
        />
      ))}
    </SpeedDial>
  )
}

export default Dial
