/** initial_controls
 * @param {Object} initial_controls
 * @param {Boolean} initial_controls.autoSolve
 * @param {Boolean} initial_controls.reset
 * @param {number} initial_controls.numberSelect
 */
const initial_controls = {
  autoSolve: false,
  create: false,
  tree: false,
  numberSelect: 0
}

/** controls_reducer
 * @param {Object} [controls=initial_controls] - state
 * @param {Object} action - type and payload
 * @param {string} action.type
 * @param {number} action.active
 * @param {string} action.ctrl - control to toggle
 */
const controls_reducer = (controls = initial_controls, action) => {
  switch (action.type) {
    case 'NUMBER_SELECT': {
      return { ...controls, number_select: action.active }
    }
    case 'TOGGLE_CONTROL': {
      return { ...controls, [action.ctrl]: !controls[action.ctrl] }
    }
    case 'CREATE_PUZZLE': {
      return { ...controls, create: true }
    }
    case 'CHANGE_PUZZLE': {
      return { ...controls, create: false }
    }
    default:
      return controls
  }
}

export default controls_reducer
