import React, { useRef } from 'react'
import { TextField, IconButton, Tooltip } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const CopyButton = ({ text, cb }) => {
  const iRef = useRef(null)

  const handleCopy = () => {
    iRef.current.select()
    document.execCommand('copy')
  }

  const openLink = () => window.open(text, '_blank')
  const link = text.startsWith('http')

  const flexStuff = {
    display: 'flex',
    justifyContent: 'space-between'
  }

  return (
    <div style={flexStuff}>
      <TextField
        fullWidth
        type='text'
        value={text}
        readOnly
        inputProps={{ ref: iRef }}
      />
      {link && (
        <Tooltip title='open' placement='top' enterDelay={1000}>
          <IconButton onClick={openLink} color='secondary'>
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title='copy' placement='top' enterDelay={1000}>
        <IconButton onMouseDown={handleCopy} onClick={cb} color='primary'>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export { CopyButton }
