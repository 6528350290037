import React, { useState } from 'react'
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  ClickAwayListener
} from '@material-ui/core'
// LOCAL
import { useStyles } from '@style'

const ContextWrapper = ({ actions, children, ...props }) => {
  const [open, $open] = useState(false)
  const [{ x, y }, $coords] = useState({ x: 0, y: 0 })

  const local = ({spacing}) => ({
    pageMenu: {
      position: 'fixed',
      top: y - 50,
      left: x - 50,
      zIndex: Math.pow(10, 10),
      padding: spacing(1, 2),
      fontSize: '1em'
    }
  })
  const c = useStyles(local)

  const handleContextMenu = event => {
    event.preventDefault()
    event.stopPropagation()
    const { clientX, clientY } = event
    $coords({ x: clientX, y: clientY })
    $open(true)
  }

  const handleClose = () => $open(false)

  const subClick = (event, fn) => {
    fn(event)
    handleClose()
  }

  const actionItem = ({
    icon,
    text,
    onClick,
    color = 'primary',
    disabled = false
  }) => (
    <ListItem
      dense
      button
      onClick={ev => subClick(ev, onClick)}
      key={text}
      disabled={disabled}>
      <ListItemIcon style={{ minWidth: '2em' }}>
        <Icon
          className={`fas fa-${icon}`}
          color={color}
          fontSize='inherit'
          style={{ width: 'fit-content' }}
        />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
  const handleClickAway = () => open && handleClose()

  return (
    <div onContextMenu={handleContextMenu} {...props}>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={c.pageMenu}>
            <Paper>
              <List>{actions.map(actionItem)}</List>
            </Paper>
          </div>
        </ClickAwayListener>
      )}
      {children}
    </div>
  )
}
export default ContextWrapper
