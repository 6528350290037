import { types, numArr } from '@util/constants'
const emptyAvail = { row: [], col: [], reg: [], all: [] }

const available_values = ({board, selected_cells }) => {
  const highlander = selected_cells.length === 1
  if (!!selected_cells.length) {
    const first = selected_cells[0]

    const isSingle = key =>
      selected_cells.every(sel => sel[key] === first[key])

    const getCountByKey = key => {
      if (!isSingle(key)) return []
      const reduceByMatch = (ac, cell) =>
        cell[key] === first[key]
          ? ac.filter(d => d !== cell.value)
          : ac
      return board.reduce(reduceByMatch, numArr)
    }

    const reduceByKey = (ac, key) => ({
      ...ac,
      [key]: getCountByKey(key),
    })

    const output = types.reduce(reduceByKey, {})

    output.all = highlander
      ? numArr.filter(d =>
          Object.values(output).every(section => section.includes(d))
        )
      : []

    return output
  } else { return emptyAvail}
}

export default available_values
