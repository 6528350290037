import { useState } from 'react'
import { useDispatch } from 'react-redux'

export const useSafety = (action) => {
  const [safety, $safety] = useState(false)
  const dp = useDispatch()
  
  const resetSafety = () => $safety(false)
  
  const toggle = (payload = null) => {
    if (safety) {
      dp(action(payload))
      resetSafety()
    } else {
      $safety(true)
    }
  }


  return [toggle, safety, resetSafety]
}
