import React from 'react'
import { Route } from 'react-router-dom'
import routes from '@pages'
import '@style/App.css'

const App = () =>
  routes.map((route, i) => (
    <Route exact={true} {...route} key={i} />
  ))

export default App
