import React from 'react'
import { useStyles } from '@style'

const BigNumber = ({ value, locked }) => {
  const pageStyle = ({
    palette: {
      primary: { main },
      secondary: { light }
    }
  }) => ({
    cell_inner: {
      fontSize: '7vmin',
      lineHeight: 1,
      textShadow: locked 
        ? 'none' 
        : `-2.5px 0px 0px ${main}, 2.5px 0px 0px ${light}`
    }
  })
  const { cell_inner } = useStyles(pageStyle)
  
  return <div className={cell_inner}>{value}</div>
}
export default BigNumber