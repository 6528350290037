import React from 'react'
import { Typography } from '@material-ui/core'
//LOCAL
import { useStyles } from '@style'
import TimerDisplay from './TimerDisplay'

const pageStyle = ({
  spacing,
  palette: {
    primary: { main },
    secondary: { light }
  }
}) => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(5px)',
    borderRadius: 5,
    margin: spacing(1),
    flexWrap: 'wrap',
    height: 'fit-content',
    paddingBottom: spacing(0.5),
    justifyContent: 'center'
  },
  title: {
    textAlign: 'center',
    textShadow: `-2.5px 0px 0px ${main}, 2.5px 0px 0px ${light}`,
    fontFamily: 'vapor',
    letterSpacing: 5,
    fontSize: '4.5vmin',
    margin: 'auto'
  },
  timer: {
    width: 'fit-content',
    height: 'fit-content',
    fontSize: '4.5vmin',
    fontWeight: 'bold',
    lineHeight: 1,
    fontFamily: 'vapor'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    marginLeft: spacing(1)
  }
})

const Timer = () => {
  const c = useStyles(pageStyle)

  return (
    <div className={c.container}>
      <Typography variant='h5' className={c.title}>
        TIMER
      </Typography>
      <div className={c.content}>
        <TimerDisplay {...{c}}/>
      </div>
    </div>
  )
}

export default Timer
