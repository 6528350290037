import React, { useState, useEffect } from 'react'
import Websocket from 'react-websocket'
import { Button, LinearProgress } from '@material-ui/core'
import { CopyButton } from '.'
import { useSnack } from '@hooks/useSnack'

const uniquifier = (ac, cu) => ac.some(entry => entry === cu) ? ac : [...ac, cu]

const MessageList = ({ paperStyle }) => {
  const [list, $list] = useState([])
  const [conn, $conn] = useState(false)
  const handleMsg = msg => $list([...list, msg])
  const handleClear = () => $list([])

  useEffect(() => {
    const uniques = list.reduce(uniquifier, [])
    if (list.length > uniques.length) $list(uniques)
  }, [list])

  const [cb, snackyBoi] = useSnack('text copied to clipboard')

  return (
    <>
      <Websocket
        url='wss://red.ad0.dev/ws/clip'
        onMessage={handleMsg}
        onOpen={() => $conn(true)}
        onClose={() => $conn(false)}
      />
      {!conn && (<LinearProgress />)}
      {!!list.length && (
        <div style={{marginTop: '2em'}}>
          {list.map((text, i) => (
            <CopyButton key={i} text={text} cb={cb} />
          ))}
          <Button onClick={handleClear} color='secondary'>
            clear entries
          </Button>
        </div>
      )}
      {snackyBoi}
    </>
  )
}

export { MessageList }
