import axios from 'axios'
//LOCAL
import { chunk, regionChunks } from '@util/constants'

export const unique = (ac, cu) => (ac.includes(cu) ? ac : [...ac, cu])
export const addition = (ac, cu) => ac + cu

export const genAction = type => payload => ({ type, payload })

// SUDOKU SECTION
// ----------------------------------------------------------------
export const sendUpdatedValue = (topic, data, sender) => {
  const fd = new FormData()
  const url = 'https://red.ad0.dev/puzzle'
  fd.append('topic', topic)
  fd.append('sender', sender)
  if (topic === 'update') {
    const { row, col, value } = data
    fd.append('row', row)
    fd.append('col', col)
    fd.append('val', value)
  }
  if (topic === 'highlight') {
    fd.append('cells', data)
  }
  axios
    .post(url, fd)
    // .then(() => console.log('data sent: ', sender))
    .catch(console.log)
}
export const chunkReducer = x => (ac, cu, i) => (cu.includes(x) ? i : ac)
export const arrToString = arr =>
  arr.map(x => x.toString()).reduce((p, c) => p + c, '')

export const findChunk = (...rc) => {
  const item = rc
    .map(dir => regionChunks.reduce(chunkReducer(dir), '').toString())
    .join('')
  return chunk.map(arrToString).indexOf(item)
}

export const mapPuzzle = puzzle =>
  puzzle
    .flatMap((row, ri) =>
      row.map((cell, ci) => ({
        value: cell,
        selected: false,
        row: ri,
        col: ci,
        reg: findChunk(ri, ci),
        locked: !!cell,
        corner: [],
        middle: [],
        color: false
      }))
    )
    .map((cell, ind) => ({ ...cell, ind }))
export const rando = max => Math.floor(Math.random() * max).toFixed(0)
export const genSender = () => 'U-' + rando(5000)

export const genReducer = (list, init = []) => (
  state = init,
  { type, action }
) => (list.includes(type) ? action(state) : state)

export const genGenType = base => str =>
  `${base.toUpperCase()}_${str
    .toUpperCase()
    .split(' ')
    .join('_')}`

const asciiColors = {
  black: '\x1b[30m',
  red: '\x1b[31m',
  green: '\x1b[32m',
  yellow: '\x1b[33m',
  blue: '\x1b[34m',
  magenta: '\x1b[35m',
  cyan: '\x1b[36m',
  lightgrey: '\x1b[37m',
  darkgrey: '\x1b[90m',
  lightred: '\x1b[91m',
  lightgreen: '\x1b[92m',
  lightyellow: '\x1b[93m',
  lightblue: '\x1b[94m',
  lightmagenta: '\x1b[95m',
  lightcyan: '\x1b[96m',
  white: '\x1b[97m',
  reset: `\x1b[0m`
}
export const c_str = (color, text) =>
  !!asciiColors[color] ? asciiColors[color] + text + asciiColors.reset : text

export const boardFromString = str =>
  str.split('-').map(row => row.split('').map(n => n * 1))

const allArrays = (...arrays) => arrays.every((arr, i) => Array.isArray(arr))
const sameLength = (...arrays) =>
  arrays.map(arr => arr.length).reduce(unique, []).length === 1
const sameElements = (...arrays) =>
  arrays.every((arr, i, curr) =>
    curr
      .filter((x, j) => j !== i)
      .every(other => other.every((ele, e) => arr[e] === ele))
  )
export const compareArrays = (...arrays) =>
  allArrays(...arrays) && sameLength(...arrays) && sameElements(...arrays)

const capWord = word =>
  word
    .split('').reduce((ac, char, i) => !i ? char.toUpperCase() : ac + char.toLowerCase(),'')
export const capitalize = str =>
  str
    .split(' ')
    .map(capWord)
    .join(' ')
